<template>
    <div>
        <v-avatar size="25" class="mx-1">
            <v-img :src="require(`../../assets/blockchain/${blockchainType}.svg`)" contain>
            </v-img>
        </v-avatar>
        <span v-if="showText">{{ blockchainType | BlockchainTypeFormatter }}</span>
    </div>
</template>

<script>
export default {
    name: "blockchain-type-component",
    props: {
        blockchainType: {
            type: String,
            required: true
        },
        showText: {
            type: Boolean,
            default: true
        }
    }

}

</script>

<style>
</style>