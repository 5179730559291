import api from '@/store/utils/api.js'

export const pagedTable = {
    namespaced: true,
    state() {
        return {
            itemEndpoint: null,
            itemsList: [],
            itemsNumber: 0,
            currentItemPage: 0
        }
    },
    getters: {
        getItemEndpoint: state => state.itemEndpoint,
        getItems: state => state.itemsList,
        getItemsNumber: state => state.itemsNumber,
        getCurrentItemPage: state => state.currentItemPage
    },
    mutations: {
        setItemEndpoint(state, itemEndpoint) { state.itemEndpoint = itemEndpoint; },
        setItemsList(state, itemsList) { state.itemsList = itemsList; },
        setItemsNumber(state, itemsNumber) { state.itemsNumber = itemsNumber; },
        setCurrentItemPage(state, currentItemPage) { state.currentItemPage = currentItemPage; },

    },
    actions: {
        fetchItems(store, opt) {
            if (!store.state.itemEndpoint) {
                return;
            }
            const paging = { page: opt.page || 0, size: opt.pageSize || 10 }
            if (opt.sortBy) {
                paging.sort = `${opt.sortBy},${opt.sortDesc ? "desc" : "asc"}`
            }
            if(opt.search){
                paging.search = opt.search
            }
            return api.get(store.state.itemEndpoint, { params: paging })
                .then((response) => {
                    store.commit('setItemsList', response.data.content);
                    store.commit('setItemsNumber', response.data.totalElements);
                    store.commit('setCurrentItemPage', response.data.pageable.pageNumber);
                });
        }
    }
}