<template>
<div v-if="address && !isExchange(address)">
  <v-btn icon @click="copyText">
    <v-icon small>mdi-clipboard-text</v-icon>
  </v-btn>
</div>

</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'copy-address-button',
    props: {
      address: {
        required: true,
      }
    },
    data () {
      return {}
    },
    methods: {
      ...mapActions("alert", ["createAlert"]),
      isExchange(address){
      return this.$SUPPORTED_EXCHANGES.some((exchange) => {
        if (address.toLowerCase().startsWith(exchange.toLowerCase())){
          return true;
        }
      })
    },
    copyText() {
      navigator.clipboard.writeText(this.address).then(() => {
        this.createAlert({message: this.$t('message.copyAddressSuccess'), type: "success"})
      }).catch(() => {
        this.createAlert({message: this.$t('message.copyAddressError'), type: "error"})
      });
    }
  }
    
}
</script>

<style>

</style>